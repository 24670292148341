import { Box, Flex, TextProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Button from '../shared/Button';
import { ButtonType } from '../../../enums';
import Card from '@/components/shared/Card';
import Heading from '@/components/shared/Heading';
import Link from '../shared/Link';
import Text from '@/components/shared/Text';

interface Props {
  body: string;
  image?: ReactNode;
  imageBackground?: string;
  isActive?: boolean;
  isExternal?: boolean;
  linkUrl: string | null;
  title: string;
  subtitle: string;
  subtitleTextProps?: TextProps;
}

const MIN_HEIGHT = '326px';

const EffortlessRiskManagementCard: React.FC<Props> = ({
  body,
  image,
  imageBackground,
  isActive = true,
  isExternal,
  linkUrl,
  title,
  subtitle,
  subtitleTextProps,
}) => {
  return (
    <Box flex="1" height="100%">
      <Card
        boxShadow="0px 5px 0px #D4E6F4"
        cursor={isActive ? 'pointer' : 'not-allowed'}
        display="flex"
        flexDirection="column"
        minH={{ md: '276px' }}
        height="100%"
        href={isActive ? linkUrl : null}
        isExternal={isExternal}
        isHoverable={isActive}
        opacity={isActive ? 1 : 0.5}
        borderWidth="2px"
        borderColor="#D4E6F4"
      >
        {image != null && (
          <Box alignSelf="center" pt="8" width="100%" background={imageBackground}>
            {image}
          </Box>
        )}
        <Flex flex="1" height="100%" flexDir="column" justifyContent="space-between" minH={MIN_HEIGHT} p="8">
          <Box display="flex" flexDirection="column" flex="1">
            <Heading fontSize="xl" mb="4" {...subtitleTextProps}>
              {subtitle}
            </Heading>
            <Heading fontSize="24px">{title}</Heading>
            <Text fontSize="lg" my="6">
              {body}
            </Text>
            <Link href={linkUrl} mt="auto">
              <Button appButtonType={ButtonType.Secondary} isFullWidth>
                Learn More
              </Button>
            </Link>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

export default EffortlessRiskManagementCard;
