import { Box, Center, Flex, Stack, useTheme } from '@chakra-ui/react';
import { ButtonType, Route } from '@/../enums';
import React, { useRef } from 'react';

import Button from '../shared/Button';
import { COZY_DISCORD_URL } from '@/constants/urls';
import EffortlessRiskManagementCard from './EffortlessRiskManagementCard';
import Heading from '../shared/Heading';
import HomePageHorizontalSectionAnimation from './HomePageHorizontalSectionAnimation';
import { Image } from '@/components/shared/Image';
import Link from '../shared/Link';
import { useInView } from 'framer-motion';

interface Props {
  isMobile: boolean;
}

const EffortlessRiskManagementSection: React.FC<Props> = ({ isMobile }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <Flex backgroundColor="#FCFDFF" justifyContent="center" width="100%">
      <Flex alignItems="center" flex="1" maxW={theme.maxPageWidth} px="8" py={{ base: '80px' }}>
        <Flex width="100%" flexDir="column">
          <Box flex="1" mb={{ base: 10, md: 20 }} textAlign={{ base: 'center', md: 'initial' }}>
            <HomePageHorizontalSectionAnimation delay={0.1} isInView={isInView}>
              <Heading fontSize={{ base: '32px', md: '44px' }}>Effortless Risk Management</Heading>
            </HomePageHorizontalSectionAnimation>
            <HomePageHorizontalSectionAnimation delay={0.2} isInView={isInView}>
              <Heading fontSize={{ base: '24px', md: '32px' }} color="#AAC6DC" fontWeight="600" maxW="644px" mt="6">
                Use Cozy v2 for your project, DAO, or fund.
              </Heading>
            </HomePageHorizontalSectionAnimation>
          </Box>
          <HomePageHorizontalSectionAnimation delay={0.3} isInView={isInView}>
            <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" ref={ref} spacing="8">
              {cards().map((card, index) => (
                <EffortlessRiskManagementCard key={index} {...card} />
              ))}
            </Stack>
          </HomePageHorizontalSectionAnimation>

          {!isMobile && (
            <HomePageHorizontalSectionAnimation delay={0.4} isInView={isInView}>
              <Box mt="20">
                <Link href={COZY_DISCORD_URL} isExternal>
                  <Button
                    appButtonType={ButtonType.Secondary}
                    color="gray.500"
                    fontWeight="600"
                    width={{ base: 'full', md: '198px' }}
                  >
                    Learn more in Discord
                  </Button>
                </Link>
              </Box>
            </HomePageHorizontalSectionAnimation>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const cards = () => [
  {
    body: 'Fund a safety module for your protocol launch that pays out if an emergency occurs.',
    image: (
      <Center>
        <Image
          alt="A widget displaying that a protocol is protected up to $100,000 using a Cozy Safety Module."
          htmlHeight="125"
          htmlWidth="293"
          src="/images/graphics/home/backstop-widget-example.png"
        />
      </Center>
    ),
    imageBackground:
      'var(--Homepage-card, linear-gradient(327deg, #FFF 25.59%, rgba(255, 255, 255, 0.00) 90.31%), linear-gradient(170deg, #E7F5FF 0.42%, rgba(255, 232, 254, 0.13) 99%))',
    linkUrl: Route.CozySafetyModule,
    title: 'Protect Your Protocol',
    subtitle: 'For Projects',
    subtitleTextProps: { color: '#7176FF' },
  },
  {
    body: 'Protect your treasury assets from tail risks.',
    image: (
      <Center py="8" px="10">
        <Image
          alt="An image of Nouns glasses ontop of their treasury asset holdings with a green shield to show that they're protected."
          htmlHeight="115"
          htmlWidth="263"
          src="/images/graphics/home/dao-treasury-protection-example.png"
        />
      </Center>
    ),
    imageBackground:
      'linear-gradient(355deg, #FFF 2.06%, rgba(255, 255, 255, 0.00) 65.28%), linear-gradient(170deg, rgba(220, 255, 246, 0.50) 0.42%, rgba(226, 255, 250, 0.50) 99%)',
    linkUrl: Route.CozyForDaos,
    title: 'Protect Your Treasury',
    subtitle: 'For DAOs',
    subtitleTextProps: { color: '#17D9B6' },
  },
  {
    body: 'Earn high risk adjusted yields by providing protection.',

    image: (
      <Center py="1">
        <Image
          alt="A Blue Chip Protection Set with 16% projected APY and 6 protection markets."
          htmlHeight="156"
          htmlWidth="282"
          src="/images/graphics/home/blue-chip-protection-set-example.png"
        />
      </Center>
    ),
    imageBackground:
      'linear-gradient(355deg, #FFF 2.06%, rgba(255, 255, 255, 0.00) 65.28%), linear-gradient(0deg, rgba(239, 236, 255, 0.60) 0%, rgba(239, 236, 255, 0.60) 100%), linear-gradient(327deg, #FFF 25.59%, rgba(255, 255, 255, 0.00) 90.31%), linear-gradient(170deg, #EFFEFF 0.42%, rgba(255, 232, 254, 0.13) 99%)',
    linkUrl: Route.ProvideProtection,
    title: 'Earn High Yields',
    subtitle: 'For Funds',
    subtitleTextProps: { color: '#987EFF' },
  },
];

export default EffortlessRiskManagementSection;
