import { Box, Center, Flex, Icon, useTheme } from '@chakra-ui/react';
import React, { useRef } from 'react';

import { COZY_DEVELOPER_DOCS_URL } from '@/constants/urls';
import Heading from '../shared/Heading';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import HomePageHorizontalSectionAnimation from './HomePageHorizontalSectionAnimation';
import Link from '../shared/Link';
import Text from '../shared/Text';
import dynamic from 'next/dynamic';
import { useInView } from 'framer-motion';

const Image = dynamic(() => import('@/components/shared/Image').then((mod) => mod.Image), { ssr: false });

export const AUDIT_DOCS_URL = COZY_DEVELOPER_DOCS_URL + 'faq/security-faq';

const CANTINA_RATIO = 142 / 660; // y / x
const CANTINA_WIDTH = 170;

interface Props {
  isMobile: boolean;
}

const AuditedByCantinaSection: React.FC<Props> = ({ isMobile }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <Flex borderBottom="1px solid" borderColor="gray.100" justifyContent="center" width="100%">
      <Flex alignItems="center" flex="1" maxW={theme.maxPageWidth} px={{ base: 8, xl: 8 }} py="100px">
        <Flex flexDir="column" ref={ref} width="100%">
          <HomePageHorizontalSectionAnimation delay={0.4} isInView={isInView}>
            <Flex alignItems="center" flexDir={{ base: 'column', md: 'row' }}>
              <Box color="white" flex="1">
                <Heading
                  color="gray.500"
                  fontSize={{ base: '32px', md: '40px' }}
                  textAlign={{ base: 'center', md: 'initial' }}
                >
                  {isMobile ? 'Audited' : 'Audited and Secure'}
                </Heading>
                <Link href={AUDIT_DOCS_URL} isExternal>
                  <Flex alignItems="center" color="gray.300">
                    <Heading fontSize={{ base: '20px', md: '32px' }} fontWeight="600" maxW="557px" mt="1">
                      <Text as="span">Protocol Security</Text>
                    </Heading>
                    <Icon
                      as={HiOutlineArrowNarrowRight}
                      fontSize={{ base: '23px', md: '36px' }}
                      fontWeight={800}
                      ml="3"
                      mt="1"
                    />
                  </Flex>
                </Link>
              </Box>

              <Link href={AUDIT_DOCS_URL} isExternal width={{ base: '100%', md: 'auto' }}>
                <Flex
                  backgroundColor="gray.50"
                  borderRadius="2xl"
                  flexDirection="column"
                  ml={{ base: 0, md: 8 }}
                  mt={{ base: 20, md: 0 }}
                  width={{ base: '100%', md: null }}
                >
                  <Center flex="1" px={{ base: '40px', md: '120px' }} py="64px" width={{ base: '100%', md: null }}>
                    <Image
                      htmlWidth={CANTINA_WIDTH}
                      htmlHeight={CANTINA_WIDTH * CANTINA_RATIO}
                      src={`/images/auditors/cantina.png`}
                      alt="To the left: A detailed set of overlapping lines, resembling a circuit board.  To the right, the Canita word mark."
                    />
                  </Center>
                  <Flex
                    backgroundColor="gray.100"
                    borderBottomRadius="2xl"
                    color="gray.300"
                    alignItems="center"
                    justifyContent="space-between"
                    px="6"
                    py="3"
                  >
                    <Text fontSize="md" fontWeight="500" textTransform="uppercase">
                      April 2023
                    </Text>
                    <Icon as={HiOutlineArrowNarrowRight} color="gray.300" fontSize="24px" />
                  </Flex>
                </Flex>
              </Link>
            </Flex>
          </HomePageHorizontalSectionAnimation>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AuditedByCantinaSection;
