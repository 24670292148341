import React, { ReactNode } from 'react';

import { SlideFade } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
  delay: number;
  isInView: boolean;
}

const HomePageHorizontalSectionAnimation: React.FC<Props> = ({ children, delay, isInView }) => {
  return (
    <SlideFade in={isInView} offsetY={'20px'} transition={{ enter: { ease: 'easeInOut', duration: 0.4, delay } }}>
      {children}
    </SlideFade>
  );
};

export default HomePageHorizontalSectionAnimation;
