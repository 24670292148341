import { Box, Flex, FlexProps, SlideFade, Stack, useTheme } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';

import CozyV2Section from './CozyV2Section';
import HeroHeading from './HomeHeroHeading';
import PulsingGraphic from './PulsingGraphic';
import SocialIcons from './SocialIcons';
import Text from '../shared/Text';

interface Props extends FlexProps {
  accentText: string;
  image?: ReactNode;
  isMobile: boolean;
  primaryButton: ReactNode;
  secondaryButton?: ReactNode;
  tertiaryButton?: ReactNode;
}

const texts = ['your project', 'yourself', 'your DAO', 'your users'];

const HeroSection: React.FC<Props> = ({
  accentText,
  image,
  isMobile,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  ...flexProps
}: Props) => {
  const theme = useTheme();
  const [mainTextIndex, setMainTextIndex] = useState(0);
  const [mainText, setMainText] = useState(texts[0]);
  const [animateMainTextIn, setAnimateMainTextIn] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate the next index in a cyclic manner
      const nextIndex = (mainTextIndex + 1) % texts.length;

      // 300ms before updating the text, set animateMainTextIn to false
      setTimeout(() => {
        setAnimateMainTextIn(false);
      }, 300);

      // After 300ms, update the text and set animateMainTextIn back to true
      setTimeout(() => {
        setMainTextIndex(nextIndex);
        setMainText(texts[nextIndex]);
        setAnimateMainTextIn(true);
      }, 600); // 600ms to account for the 300ms delay
    }, 2000); // 2 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [mainTextIndex]);

  return (
    <Flex
      flex={1}
      alignItems="center"
      justifyContent={{ base: 'center', md: 'space-between' }}
      position="relative"
      {...flexProps}
    >
      <Flex flexDir="column" flex={{ base: 1 }} mb="8" position="relative" top={{ md: '-40px' }}>
        {/* MOBILE GRAPHIC */}

        {isMobile && <PulsingGraphic image={image} isMobile={isMobile} />}

        {!isMobile && <CozyV2Section />}

        <Box position="relative" top={{ base: '-20px', md: '4px' }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={{ base: 'center', md: 'initial' }}
            maxW={maxTextWidth(image != null)}
            textAlign={{ base: 'center', md: 'left' }}
          >
            {isMobile && (
              <Box py="4">
                <CozyV2Section />
              </Box>
            )}
            <HeroHeading color={theme.utilityColors.brandColor}>{accentText}</HeroHeading>
            {!isMobile ? (
              <HeroHeading baseFontSize="24px">
                <Flex>
                  <Text as="span">Protect&nbsp;</Text>
                  <SlideFade in={animateMainTextIn} transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}>
                    <Text as="span">{mainText}</Text>
                  </SlideFade>
                </Flex>
                <Text as="span">from hacks.</Text>
              </HeroHeading>
            ) : (
              <SlideFade in={animateMainTextIn} transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}>
                <HeroHeading baseFontSize="22px" mt="4">
                  Protect {mainText} from hacks.
                </HeroHeading>
              </SlideFade>
            )}
          </Box>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            mt={{ base: 10, md: '2em' }}
            spacing={{ base: 4, md: 6 }}
            mr={{ md: secondaryButton == null ? '192px' : 'initial' }}
          >
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 4, md: '1px' }}>
              {primaryButton}
              {secondaryButton}
            </Stack>

            {tertiaryButton}

            <Box alignSelf="center">
              <SocialIcons position="relative" top="3px" />
            </Box>
          </Stack>
        </Box>
      </Flex>

      {/* DESKTOP GRAPHIC */}
      <Box display={{ base: 'none', lg: 'block' }} mb="16" position="relative">
        <Box position="relative" left={{ base: 0, md: -10, lg: -10, xl: 0 }}>
          {!isMobile && <PulsingGraphic image={image} isMobile={isMobile} />}
        </Box>
      </Box>
    </Flex>
  );
};

const maxTextWidth = (hasImage: boolean) => {
  if (hasImage) {
    return { sm: '100%', md: '560px', lg: '600px', xl: '1000px' };
  } else {
    return { sm: '100%', md: '580px', lg: '660px', xl: '920px' };
  }
};

export default HeroSection;
