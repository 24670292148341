import { Box, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Card from '@/components/shared/Card';
import Heading from '@/components/shared/Heading';
import Text from '@/components/shared/Text';

interface Props {
  body: string;
  image?: ReactNode;
  isActive?: boolean;
  linkUrl: string | null;
  title: string;
}

const MIN_HEIGHT = '72px';

const TheCozyProtocolCard: React.FC<Props> = ({ body, image, isActive = true, linkUrl, title }) => {
  return (
    <Box flex="1" height="100%">
      <Card
        _hover={{ backgroundColor: 'gray.100' }}
        boxShadow="0px 5px 0px rgba(0, 0, 0, 0.2)"
        cursor={isActive ? 'pointer' : 'not-allowed'}
        flex="1"
        minH={{ md: '276px' }}
        height="100%"
        href={isActive ? linkUrl : null}
        isHoverable={isActive}
        opacity={isActive ? 1 : 0.5}
        p="8"
      >
        <Flex flex="1" height="100%" flexDir="column" justifyContent="space-between" minH={MIN_HEIGHT}>
          {image != null && image}

          <Box flex="1">
            <Heading fontSize="3xl">{title}</Heading>
            <Text fontSize="lg" mt="4">
              {body}
            </Text>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};

export default TheCozyProtocolCard;
