import { Flex, SimpleGrid, useTheme } from '@chakra-ui/react';
import React, { useRef } from 'react';

import Container from '@/components/shared/Container';
import Heading from '@/components/shared/Heading';
import HomePageHorizontalSectionAnimation from './HomePageHorizontalSectionAnimation';
import { Image } from '@/components/shared/Image';
import Text from '@/components/shared/Text';
import { useInView } from 'framer-motion';

const investorLogos = ['polychain', 'electric', 'variant', 'dragonfly', 'robot', 'cms', 'coinbase'];

const CONTAINER_WIDTH = '135px';
const CONTAINER_HEIGHT = '122px';

const Investors: React.FC = () => {
  const theme = useTheme();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <Flex alignItems="center" flex="1" maxW={theme.maxPageWidth} py={{ base: '80px', md: '120px' }}>
      <Flex alignItems="center" flex="1" flexDir="column" ref={ref}>
        <Flex px={{ base: 4, xl: 8 }} flexDir="column">
          <HomePageHorizontalSectionAnimation delay={0.1} isInView={isInView}>
            <Heading color="gray.300" fontSize="32px" fontWeight="600" textAlign={{ base: 'left', md: 'center' }}>
              <Text as="span" color="gray.500">
                Backed
              </Text>
              <span>&nbsp;by the best</span>
            </Heading>
          </HomePageHorizontalSectionAnimation>
        </Flex>

        <HomePageHorizontalSectionAnimation delay={0.2} isInView={isInView}>
          <SimpleGrid columns={{ base: 2, md: 7 }} mt={{ base: 10, md: 11 }}>
            {investorLogos.map((investor, index) => (
              <Container width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT} key={index} border="0px">
                <Image htmlWidth="152" htmlHeight="138" src={`/images/investors/${investor}.png`} alt={investor} />
              </Container>
            ))}
          </SimpleGrid>
        </HomePageHorizontalSectionAnimation>
      </Flex>
    </Flex>
  );
};
export default Investors;
