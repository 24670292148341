import { Box, BoxProps, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import Link from './Link';
import useIsMobileDisplay from '@/hooks/useIsMobileDisplay';
import { useRouter } from 'next/router';

interface Props extends BoxProps {
  children: ReactNode;
  containerWidth?: string;
  href?: string;
  isExternal?: boolean;
  isHoverable?: boolean;
}

const Card: React.FC<Props> = ({ children, containerWidth, href, isExternal, isHoverable = true, ...props }: Props) => {
  const theme = useTheme();
  const isMobile = useIsMobileDisplay();
  const router = useRouter();

  const cursorAttribute = { cursor: 'pointer' };
  const boxShadowAttribute = { boxShadow: `0px 0px 0px 2px ${theme.utilityColors.accentColor}` };
  const outlineAttribute = { outline: `2px solid ${theme.utilityColors.accentColor}`, outlineOffset: '-1px' };
  const highlightAttribute = isMobile ? boxShadowAttribute : outlineAttribute;

  const hoverableProps = isHoverable
    ? {
        _hover: {
          ...cursorAttribute,
          ...highlightAttribute,
        },
      }
    : {};

  const card = (
    <Box
      backgroundColor="white"
      borderWidth="1px"
      borderRadius="lg"
      borderColor="rgba(237, 239, 242, 0.75)"
      boxShadow={{ base: 'none', md: 'cozyBoxGray' }}
      outline={'2px solid transparent'}
      transition="ease-in-out 0.15s"
      {...hoverableProps}
      {...props}
    >
      {children}
    </Box>
  );

  return isExternal ? (
    <Link href={href} isExternal>
      {card}
    </Link>
  ) : (
    <Box onClick={setupOnClick(router, href)} width={containerWidth}>
      {card}
    </Box>
  );
};

const setupOnClick = (router, href) => () => {
  if (href != null) {
    router.push(href);
  }
};

export default Card;
