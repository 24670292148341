import { ButtonType, Route } from '@/../enums';
import { useBreakpointValue, useTheme } from '@chakra-ui/react';

import Button from '@/components/shared/Button';
import { COZY_DISCORD_URL } from '@/constants/urls';
import FullScreenSection from './FullScreenSection';
import HeroSection from '@/components/home/HeroSection';
import Link from '@/components/shared/Link';
import Lottie from 'lottie-react';
import React from 'react';
import heroGraphic from '@/../public/images/animations/pulsing-hero-graphic.json';
import { min } from 'lodash';
import { useWindowWidth } from '@react-hook/window-size';

interface Props {
  isMobile: boolean;
}

const HomeHeroSection: React.FC<Props> = ({ isMobile }) => {
  const theme = useTheme();
  const windowWidth = useWindowWidth();
  const maxGraphicWidth = min([windowWidth - 16 * 4, 420]);

  const graphicWidth = useBreakpointValue({ base: `${maxGraphicWidth}px`, md: '260px', lg: '530px' });

  const pulsingGraphic = <Lottie animationData={heroGraphic} style={{ margin: 'auto', width: graphicWidth }} />;

  return (
    <FullScreenSection hasHeader isMobile={isMobile} pt={{ base: 0, md: null }}>
      <HeroSection
        accentText="The protection protocol."
        image={pulsingGraphic}
        isMobile={isMobile}
        maxW={theme.maxPageWidth}
        px="8"
        primaryButton={
          <Link href={Route.GetProtection}>
            <Button appButtonType={ButtonType.Primary} width={{ base: 'full', md: '192px' }}>
              Get Started
            </Button>
          </Link>
        }
        tertiaryButton={
          <Link href={COZY_DISCORD_URL} isExternal>
            <Button appButtonType={ButtonType.Secondary} borderColor="black" width={{ base: 'full', md: '152px' }}>
              Questions?
            </Button>
          </Link>
        }
      />
    </FullScreenSection>
  );
};

export default HomeHeroSection;
