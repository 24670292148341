import React, { ReactNode } from 'react';

import { Box } from '@chakra-ui/react';

interface Props {
  isMobile: boolean;
  image?: ReactNode;
}

const PulsingGraphic: React.FC<Props> = ({ image, isMobile }) => {
  const boxProps = isMobile ? { height: '350px' } : {};

  return <Box {...boxProps}>{image}</Box>;
};

export default PulsingGraphic;
