import { Box, Center, Flex, Icon, Stack, useTheme } from '@chakra-ui/react';
import React, { useRef } from 'react';

import { BsShieldFillCheck } from 'react-icons/bs';
import FullScreenSection from './FullScreenSection';
import Heading from '../shared/Heading';
import HomePageHorizontalSectionAnimation from './HomePageHorizontalSectionAnimation';
import { Image } from '@/components/shared/Image';
import { Route } from '@/../enums';
import Text from '../shared/Text';
import TheCozyProtocolCard from './TheCozyProtocolCard';
import { useInView } from 'framer-motion';

interface Props {
  isMobile: boolean;
}

const TheCozyProtocolSection: React.FC<Props> = ({ isMobile }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <FullScreenSection backgroundColor={theme.utilityColors.accentColor} isMobile={isMobile}>
      <Flex alignItems="center" flex="1" maxW={theme.maxPageWidth} px="8">
        <Flex width="100%" flexDir="column">
          <Flex mb={{ base: 10, md: 20 }} ref={ref}>
            <Box color="white" flex="1" textAlign={{ base: 'center', md: 'initial' }}>
              <HomePageHorizontalSectionAnimation delay={0.1} isInView={isInView}>
                <Heading fontSize={{ base: '32px', md: '44px' }}>The Cozy Protocol</Heading>
              </HomePageHorizontalSectionAnimation>
              <HomePageHorizontalSectionAnimation delay={0.2} isInView={isInView}>
                <Heading fontSize={{ base: '24px', md: '32px' }} fontWeight="600" maxW="644px" mt="6">
                  Cozy v2 empowers teams to create markets for protection against hacks.
                </Heading>
              </HomePageHorizontalSectionAnimation>
            </Box>
          </Flex>

          <HomePageHorizontalSectionAnimation delay={0.3} isInView={isInView}>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              spacing={{ base: 8, md: 10, lg: 12, xl: 16 }}
            >
              {cards(theme.utilityColors).map((card, index) => (
                <TheCozyProtocolCard key={index} {...card} />
              ))}
            </Stack>
          </HomePageHorizontalSectionAnimation>
        </Flex>
      </Flex>
    </FullScreenSection>
  );
};

const cards = ({ successColor, successSecondaryColor }) => [
  {
    body: 'Purchase protection against events like hacks, depegs, and more.',
    image: (
      <Center backgroundColor="#A6A9FF33" borderRadius="full" height="56px" mb="8" width="56px">
        <Icon as={BsShieldFillCheck} color="#A6A9FF" fontSize="22.4px" />
      </Center>
    ),
    linkUrl: Route.GetProtection,
    title: 'Get Protection',
  },
  {
    body: 'Earn yield by underwriting against protection markets.',
    image: (
      <Center backgroundColor={successSecondaryColor} borderRadius="full" height="56px" mb="8" width="56px">
        <Text color={successColor} fontWeight="800" fontSize="22.4px">
          %
        </Text>
      </Center>
    ),
    linkUrl: Route.ProvideProtection,
    title: 'Provide Protection',
  },
  {
    body: 'Define payout logic to enable users to get and provide protection.',

    image: (
      <Box mb="25px">
        <Image
          alt="A plus sign inside of a circle, representing listing a new market"
          htmlHeight="56"
          htmlWidth="56"
          src="/images/graphics/home/admin-plus-circle.png"
        />
      </Box>
    ),
    linkUrl: Route.CreateProtection,
    title: 'List a Market',
  },
];

export default TheCozyProtocolSection;
