import Card from '@/components/shared/Card';
import { Flex } from '@chakra-ui/react';
import Heading from '@/components/shared/Heading';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  body: string;
  isActive?: boolean;
  linkUrl?: string | null;
  title: string;
}

const GetStartedWithCozyCard: React.FC<Props> = ({ body, isActive = true, linkUrl, title }) => {
  return (
    <Card
      boxShadow="0px 5px 0px #A6A9FF"
      cursor={isActive ? 'pointer' : 'not-allowed'}
      display="flex"
      flexDirection="column"
      height="246px"
      href={isActive ? linkUrl : null}
      isExternal
      isHoverable={isActive}
      opacity={isActive ? 1 : 0.5}
      borderWidth="2px"
      borderColor="#A6A9FF"
      my="1"
    >
      <Flex height="100%" flexDir="column" p="8">
        <Heading fontSize="2xl">{title}</Heading>
        <Text
          color="gray.500"
          flex="1"
          fontSize="lg"
          mt="4"
          overflow="hidden"
          position="relative"
          _after={{
            content: `""`,
            position: 'absolute',
            zIndex: 1,
            bottom: 0,
            left: 0,
            pointerEvents: 'none',
            backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%)',
            width: '100%',
            height: '3em',
          }}
        >
          {body}
        </Text>
        <Text color="gray.300" fontWeight="600" fontSize="md" pt="2">
          Read more
        </Text>
      </Flex>
    </Card>
  );
};

export default GetStartedWithCozyCard;
