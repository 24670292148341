import 'swiper/css';
import 'swiper/css/navigation';

import { Box, Flex, Stack, useTheme } from '@chakra-ui/react';
import { ButtonType, Route } from '../../../enums';
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import BackArrow from '../shared/BackArrow';
import Button from '../shared/Button';
import { COZY_DEVELOPER_DOCS_URL } from '@/constants/urls';
import FullScreenSection from './FullScreenSection';
import GetStartedWithCozyCard from './GetStartedWithCozyCard';
import Heading from '../shared/Heading';
import HomePageHorizontalSectionAnimation from './HomePageHorizontalSectionAnimation';
import Link from '../shared/Link';
import { Navigation } from 'swiper/modules';
import SocialIcons from './SocialIcons';
import { useInView } from 'framer-motion';

interface Props {
  isMobile: boolean;
}

const GetStartedWithCozySection: React.FC<Props> = ({ isMobile }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [swiperRef, setSwiperRef] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const onSlideChange = (swiper) => {
    if (swiper.isBeginning) setIsBeginning(true);
    else if (swiper.isEnd) setIsEnd(true);
    else {
      setIsBeginning(false);
      setIsEnd(false);
    }
  };

  return (
    <FullScreenSection backgroundColor="#FAF6FF" isMobile={isMobile}>
      <Flex
        alignItems="center"
        justifyContent="center"
        flex="1"
        flexDirection="column"
        height="100%"
        maxW={theme.maxPageWidth}
        px="8"
      >
        <Flex width="100%" flexDir="column" mb={{ base: 10, md: 20 }}>
          <Box flex="1" textAlign={{ base: 'center', md: 'initial' }}>
            <HomePageHorizontalSectionAnimation delay={0.1} isInView={isInView}>
              <Heading fontSize={{ base: '32px', md: '44px' }}>Get Started with Cozy</Heading>
            </HomePageHorizontalSectionAnimation>
            <HomePageHorizontalSectionAnimation delay={0.2} isInView={isInView}>
              <Heading color="gray.500" fontSize={{ base: '24px', md: '32px' }} fontWeight="600" maxW="644px" mt="6">
                Explore the app, read the documentation, or check out our blog posts below.
              </Heading>
            </HomePageHorizontalSectionAnimation>
          </Box>
          <HomePageHorizontalSectionAnimation delay={0.3} isInView={isInView}>
            <Stack direction={{ base: 'column', md: 'row' }} mt={{ base: 10 }} spacing={{ base: 6 }} ref={ref}>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="6">
                <Link href={Route.GetProtection}>
                  <Button appButtonType={ButtonType.Primary} isFullWidth={isMobile} minW={{ md: '190px' }}>
                    Explore the App
                  </Button>
                </Link>
                <Link href={COZY_DEVELOPER_DOCS_URL}>
                  <Button appButtonType={ButtonType.Secondary} isFullWidth={isMobile} minW={{ md: '190px' }}>
                    Documentation
                  </Button>
                </Link>
              </Stack>

              <Box alignSelf="center">
                <SocialIcons color="#A6A9FF" position="relative" spacing="6" top="3px" />
              </Box>
            </Stack>
          </HomePageHorizontalSectionAnimation>
        </Flex>
        <Box width="100%">
          {!isMobile ? (
            <HomePageHorizontalSectionAnimation delay={0.4} isInView={isInView}>
              <Swiper
                onSwiper={setSwiperRef}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={3}
                onSlideChange={onSlideChange}
                // slidesPerGroup={3}
                spaceBetween={64}
              >
                {cards.map((card, index) => (
                  <SwiperSlide key={index}>
                    <GetStartedWithCozyCard body={card.body} isActive linkUrl={card.linkUrl} title={card.title} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Box color="#A6A9FF" mt="16">
                <BackArrow cursor={isBeginning ? 'not-allowed' : 'pointer'} onClick={() => swiperRef.slidePrev()} />
                <BackArrow
                  cursor={isEnd ? 'not-allowed' : 'pointer'}
                  onClick={() => swiperRef.slideNext()}
                  transform="scaleX(-1)"
                />
              </Box>
            </HomePageHorizontalSectionAnimation>
          ) : (
            <>
              {cards.map((card, index) => (
                <Box mt={index != 0 ? '10' : '0'} key={index}>
                  <GetStartedWithCozyCard body={card.body} isActive linkUrl={card.linkUrl} title={card.title} />
                </Box>
              ))}
            </>
          )}
        </Box>
      </Flex>
    </FullScreenSection>
  );
};

const cards = [
  {
    body: 'Meet the Cozy v2 protocol, a protection marketplace for on-chain yield seekers. Now live on Optimism.',
    linkUrl: 'https://mirror.xyz/cozy.eth/w24TU4ksefu7-jF1AGBrIbfeNKuZAH9v41aJw_VAr4g',
    title: 'Cozy v2: the Protection Protocol',
  },
  {
    title: 'How Cozy Protection Works: Euler Case Study',
    linkUrl: 'https://mirror.xyz/cozy.eth/FuUXTaZmw2mQAX_YkelPyA0Ky8f5hYhZEAD1vba1n84',
    body: 'In early March of 2023, Euler Finance was hacked for nearly $200M. At the time, Cozy v2 was in early access and had an active Euler Finance market. This market triggered and paid out.',
  },
  {
    body: 'NounsDAO holds ~$50M in assets, with about half of those assets in Lido staked Ether (stETH). Members of the DAO have long wanted to hedge risks associated with the liquid staking protocol, but they had a problem: nobody was offering protection. The Cozy v2 protocol solved this problem.',
    linkUrl: 'https://mirror.xyz/cozy.eth/4-9XEm_5vrQj0PNSUf574FCv3TPpGGQwUxaC1GnG9AU',
    title: 'NounsDAO protects their treasury using Cozy v2 (trustlessly)',
  },
  {
    body: 'DeFi can offer valuable opportunities but those opportunities come with risks, like security vulnerabilities. The Cozy v2 Protocol (Cozy v2) offers a way to protect against those risks.',
    linkUrl: 'https://mirror.xyz/cozy.eth/djvG1VRr08GP85ua-stmwQ2vCt8kh6pWjL-VOyZEl5E',
    title: 'How to Buy Protection on Cozy v2',
  },
  {
    body: 'Historically, only a fraction of projects that suffered hacks had protection available on any platform. This is largely because of the cumbersome processes required by protection platforms to support new projects. Cozy v2 changes this with a permissionless interface, enabling the creation of markets covering any protocol.',
    linkUrl: 'https://mirror.xyz/cozy.eth/X4gAfiZLI8kYc6Y2_IkWaVdxfRBCAdH2r0g7zY0TM3A',
    title: 'How to Create Protection Markets with Cozy v2',
  },
  {
    body: 'In the Cozy V2 protocol, a market’s cost model sets the costs for purchasing protection and refunds for cancelling protection. Costs paid by protection purchasers are set aside in a fee pool and slowly accrue to protection suppliers as yield.',
    linkUrl: 'https://mirror.xyz/cozy.eth/K-4_Xrqzft-MCHCwd02bm8iLFdQbLyqgEL08mkY6SF8',
    title: 'Dynamic level cost models',
  },
];

export default GetStartedWithCozySection;
