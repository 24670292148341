import AuditedByCantinaSection from '@/components/home/AuditedByCantinaSection';
import EffortlessRiskManagementSection from '@/components/home/EffortlessRiskManagementSection';
import { Flex } from '@chakra-ui/react';
import GetStartedWithCozySection from '@/components/home/GetStartedWithCozySection';
import Head from 'next/head';
import HomeHeroSection from '@/components/home/HomeHeroSection';
import Investors from '@/components/home/Investors';
import React from 'react';
import TheCozyProtocolSection from '@/components/home/TheCozyProtocolSection';
import useFullScreenSectionHeight from '@/hooks/useFullScreenSectionHeight';
import useIsMobileDisplay from '@/hooks/useIsMobileDisplay';

const Index: React.FC = () => {
  const isMobile = useIsMobileDisplay();
  const { sectionHeight } = useFullScreenSectionHeight(true);

  if (isMobile == null || sectionHeight == null) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Cozy</title>
      </Head>

      <Flex backgroundColor="white" flexDir="column" alignItems="center">
        <HomeHeroSection isMobile={isMobile} />

        <EffortlessRiskManagementSection isMobile={isMobile} />

        <TheCozyProtocolSection isMobile={isMobile} />

        <GetStartedWithCozySection isMobile={isMobile} />

        <AuditedByCantinaSection isMobile={isMobile} />

        <Investors />
      </Flex>
    </>
  );
};

export default Index;
