import { useBreakpointValue, useTheme } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';

import { GeoblockContext } from '@/contexts/GeoblockContext';
import { useWindowHeight } from '@react-hook/window-size';

export const MIN_FULL_SCREEN_SECTION_HEIGHT = 450;
export const MAX_FULL_SCREEN_SECTION_HEIGHT = 1000;

const useFullScreenSectionHeight = (hasHeader: boolean): { sectionHeight: number } => {
  const theme = useTheme();
  const [sectionHeight, setSectionHeight] = useState(MAX_FULL_SCREEN_SECTION_HEIGHT);
  const { isBlocked } = useContext(GeoblockContext);

  const headerHeight = useBreakpointValue(theme.headerHeight);
  const windowHeight = useWindowHeight();

  useEffect(() => {
    if (windowHeight != null) {
      const geoblockBannerHeight = isBlocked && hasHeader ? 35 : 0;
      const conditionalHeaderHeight = hasHeader ? headerHeight : 0;

      setSectionHeight(windowHeight - conditionalHeaderHeight - geoblockBannerHeight);
    }
  }, [windowHeight, hasHeader, headerHeight, isBlocked]);

  return { sectionHeight };
};

export default useFullScreenSectionHeight;
