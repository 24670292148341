import Heading from '@/components/shared/Heading';
import { HeadingProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends HeadingProps {
  baseFontSize?: string;
}

const HeroHeading: React.FC<Props> = ({ baseFontSize = '31px', children, ...props }) => {
  return (
    <Heading fontWeight={800} fontSize={{ base: baseFontSize, md: '46px', lg: '51px', xl: '56px' }} {...props}>
      {children}
    </Heading>
  );
};
export default HeroHeading;
