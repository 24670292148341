import { Box, Flex, useTheme } from '@chakra-ui/react';

import Heading from '../shared/Heading';
import React from 'react';

const CozyV2Section: React.FC = () => {
  const theme = useTheme();

  return (
    <Box position={{ md: 'absolute' }}>
      <Flex
        alignItems="center"
        alignSelf={{ base: 'center', md: 'start' }}
        bottom={{ base: 4, md: 10 }}
        color={theme.utilityColors.accentColor}
        float="left"
        fontWeight="800"
        opacity={0.2}
        position="relative"
        textTransform="uppercase"
      >
        <Heading fontSize="lg">Cozy </Heading>
        <Box
          borderTop="1px solid"
          borderColor={theme.utilityColors.accentColor}
          height="1px"
          mx="2"
          position="relative"
          top="-1px"
          width="16px"
        />
        <Heading fontSize="lg">v2</Heading>
      </Flex>
    </Box>
  );
};

export default CozyV2Section;
