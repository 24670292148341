import { Flex, FlexProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import useFullScreenSectionHeight, {
  MAX_FULL_SCREEN_SECTION_HEIGHT,
  MIN_FULL_SCREEN_SECTION_HEIGHT,
} from '@/hooks/useFullScreenSectionHeight';

import { clamp } from 'lodash';

interface Props extends FlexProps {
  children: ReactNode;
  hasHeader?: boolean;
  isMobile: boolean;
  ref?: React.MutableRefObject<any>;
  usePaddedLayout?: boolean;
}

const FullScreenSection: React.FC<Props> = ({ children, hasHeader, isMobile, ref, usePaddedLayout, ...flexProps }) => {
  const { sectionHeight } = useFullScreenSectionHeight(hasHeader);

  if (isMobile || usePaddedLayout) {
    return (
      <Flex py="20" justifyContent="center" {...flexProps}>
        {children}
      </Flex>
    );
  }

  const height = clamp(sectionHeight, MIN_FULL_SCREEN_SECTION_HEIGHT, MAX_FULL_SCREEN_SECTION_HEIGHT);

  return (
    <Flex height={`${height}px`} width="100%" justifyContent="center" ref={ref} {...flexProps}>
      {children}
    </Flex>
  );
};

export default FullScreenSection;
