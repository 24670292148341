import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Props extends BoxProps {
  children: ReactNode;
}

const Container: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <Box
      p={6}
      borderRadius={{ base: 'none', md: 'xl' }}
      borderWidth={{ base: 0, md: '1px' }}
      borderBottomWidth="1px"
      borderTopWidth="1px"
      borderColor="gray.100"
      {...props}
    >
      {children}
    </Box>
  );
};

export default Container;
