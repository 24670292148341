import { Icon, IconProps } from '@chakra-ui/react';

import { CgArrowLeft } from 'react-icons/cg';
import React from 'react';

interface Props extends IconProps {
  onClick: () => void;
}

const BackArrow: React.FC<Props> = ({ onClick, ...boxProps }) => {
  return <Icon as={CgArrowLeft} boxSize="6" cursor="pointer" mb="3px" mr="4" onClick={onClick} {...boxProps} />;
};

export default BackArrow;
